import React from "react";
import Root from "../components/Root";
import SEO from "../components/seo";
import TermsPage from "../components/Terms/TermsPage";

const Terms = () => {
  return (
    <Root>
      <SEO title="Terms" />
      <TermsPage />
    </Root>
  );
};

export default Terms;
